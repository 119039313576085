import { noop } from 'lodash';

const DISTANCE_THRESHOLD = 50;
type ScrollDirection = 'up' | 'down' | 'none';

const shouldScrollUp = (currentY: number, scrollTop: number) => currentY < DISTANCE_THRESHOLD && scrollTop > 0;
const shouldScrollDown = (currentY: number, bottomDistance: number, maxHeight: number) =>
  bottomDistance < DISTANCE_THRESHOLD && maxHeight - currentY > DISTANCE_THRESHOLD;

export interface ContinuousScrollingParams {
  /** method to call before page is scrolled */
  onBeforeScroll: () => void;
  /** how far from the screen's edge scrolling should start */
  scrollDistance: number;
  /** amount of pixels to scroll */
  scrollDuration: number;

  scrollElement?: HTMLElement;
}

/**
 * This class contains logic for continuous scrolling, e.g. if you want to scroll a page within mouse selection or dragging an item
 * Use methods:
 * startScrolling - use this method to start scrolling
 * stopScrolling - use this method to stop scrolling
 */
export class ContinuousScrolling {
  private mouseEvent: MouseEvent | null;
  private params: ContinuousScrollingParams;
  private interval: number;
  private animationComplete: boolean;
  private animationId: number;

  constructor(params: Partial<ContinuousScrollingParams>) {
    this.mouseEvent = null;
    this.animationId = 0;
    this.animationComplete = false;
    this.interval = 0;
    this.params = {
      onBeforeScroll: noop,
      scrollDistance: 100,
      scrollDuration: 100,
      ...params,
    };
  }

  // This method returns correct scroll direction and scroll speed. The closer bottom or top edge you are, the fatser you scroll
  private getScrollDirection = (
    element: HTMLElement,
  ): {
    direction: ScrollDirection | null;
    duration: number;
  } => {
    if (this.mouseEvent) {
      const height = window.innerHeight;
      const currentY = element.scrollTop + this.mouseEvent.clientY;
      const bottomDistance = height - this.mouseEvent.clientY;

      const maxHeight = element.scrollHeight;

      if (shouldScrollUp(this.mouseEvent.clientY, element.scrollTop)) {
        const duration = (DISTANCE_THRESHOLD - this.mouseEvent.clientY) / DISTANCE_THRESHOLD;
        return { direction: 'up', duration: this.params.scrollDuration / duration };
      } else if (shouldScrollDown(currentY, bottomDistance, maxHeight)) {
        const duration = (DISTANCE_THRESHOLD - bottomDistance) / DISTANCE_THRESHOLD;
        return { direction: 'down', duration: this.params.scrollDuration / duration };
      }
    }
    return { direction: 'none', duration: 0 };
  };

  private scrollStep = ({
    element,
    startTime,
    duration,
    startY,
    direction,
    scrollY,
  }: {
    element: HTMLElement;
    startTime: number;
    duration: number;
    startY: number;
    direction: ScrollDirection;
    scrollY: number;
  }) => {
    if (this.mouseEvent === null) {
      return;
    }
    window.clearInterval(this.interval);
    const now = new Date().getTime();
    const completeness = (now - startTime) / duration;
    element.scrollTo(0, Math.round(startY + completeness * scrollY));
    const currentY = element.scrollTop;

    if (direction === 'up') {
      // if the page is scrolled up - finish animation
      if (currentY === 0) {
        this.animationComplete = true;
      }
    } else if (direction === 'down') {
      const limitY = element.scrollHeight;

      // if the page is scrolled down - finish animation
      if (currentY + window.innerHeight === limitY) {
        this.animationComplete = true;
      }
    }

    const nextScroll = this.getScrollDirection(element);

    // if animation is finished or mouse button is not down finish scrolling
    if (this.animationComplete || nextScroll.direction !== direction) {
      if (this.mouseEvent && direction === 'down') {
        const oldScrollHeight = element.scrollHeight;

        // if we went to page down, wait for 10 seconds to check if new items are loaded
        // if so - continue loading
        let counter = 0;
        window.clearInterval(this.interval);
        this.interval = window.setInterval(() => {
          if (direction === 'down' && oldScrollHeight !== element.scrollHeight) {
            const evt = this.mouseEvent;
            this.stopScrolling();
            this.startScrolling(evt!);
            counter++;
            if (counter === 10) {
              window.clearInterval(this.interval);
            }
          }
        }, 1000);
      }
      return;
    } else {
      if (this.params.onBeforeScroll) {
        this.params.onBeforeScroll();
      }
      this.animationId = window.requestAnimationFrame(() =>
        this.scrollStep({
          element,
          direction,
          startY,
          startTime,
          duration: nextScroll.duration,
          scrollY,
        }),
      );
    }
  };

  private scrollByAnimated = (element: HTMLElement, scrollY: number, direction: ScrollDirection, duration: number) => {
    this.animationComplete = false;
    const startTime = new Date().getTime();

    const startY = element.scrollTop;

    if (duration === undefined) {
      duration = 250; //ms
    }
    /*start animation*/
    this.scrollStep({
      element,
      duration,
      startTime,
      startY,
      direction,
      scrollY,
    });
  };

  private scrollPage = (element: HTMLElement, direction: ScrollDirection, scrollDuration: number) => {
    this.scrollByAnimated(
      element,
      direction === 'up' ? -this.params.scrollDistance : this.params.scrollDistance,
      direction,
      scrollDuration,
    );
  };

  /**
   * Use this method to start scrolling
   */
  startScrolling = (event: MouseEvent) => {
    const main = this.params.scrollElement;

    if (!main) {
      return;
    }

    this.mouseEvent = event;

    const { direction: scrollDirection, duration } = this.getScrollDirection(main);

    if (scrollDirection === 'up') {
      this.scrollPage(main, 'up', duration);
    } else if (scrollDirection === 'down') {
      this.scrollPage(main, 'down', duration);
    }
  };

  /**
   * Use this method to stop scrolling
   */
  stopScrolling = () => {
    this.mouseEvent = null;
    if (this.animationId) {
      window.cancelAnimationFrame(this.animationId);
      window.clearInterval(this.interval);
      this.animationId = 0;
    }
  };
}
